import React from "react"
import Layout from "../components/layout"
import CTAInLink from "../components/CTAInLink"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const Person = ({ data: person, order }) => {
  return (
    <div
      className={`lg:bg-transparent lg:hover:bg-gray-100 md:mt-16 md:flex md:items-center ${
        order === 1 ? "lg:border-l" : "lg:border-r"
      } lg:border-b border-gray-600  
      md:max-w-full md:mt-24 mt-16 md:px-16 sm:px-12 px-8 md:py-16 sm:py-12 py-8 lg:bg-transparent bg-gray-100 lg:hover:bg-gray-200 max-w-md sm:max-w-lg  mx-auto lg:max-w-full lg:border-t-0 `}
    >
      {person.mainImage !== null ? (
        <div className="md:pr-4">
          <Img fixed={person.mainImage.asset.fixed} />
        </div>
      ) : (
        ""
      )}
      <div className="md:pl-4 md:py-12">
        {person.name !== null ? (
          <h3 className="text-2xl">{person.name}</h3>
        ) : (
          ""
        )}
        {person.designation !== null ? (
          <h4 className="text-lg">{person.designation}</h4>
        ) : (
          ""
        )}
        {person.description !== null ? (
          <p className="text-base">{person.description}</p>
        ) : (
          ""
        )}
        {person.whatDoIDoAtBloom !== null ? (
          <p className="text-base mt-2">
            <div className="leading-tight">{person.whatDoIDoAtBloom}</div>
          </p>
        ) : (
          ""
        )}
        <div className="mt-6">
          {person.funQuestion !== null ? (
            <p className="text-sm">
              <span className="font-medium">{person.funQuestion} </span>
              {person.funAnswer ? ": " + person.funAnswer : ""}
            </p>
          ) : (
            ""
          )}
          <p className="text-sm mt-2">
            <span className="">Position Status:</span>{" "}
            <span className="bg-blue-800 rounded-full px-2 text-white">
              {person.currentlyActive ? "Active" : "Staff Alumni"}
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query {
    boysGang: file(relativePath: { eq: "boys-gang.jpeg" }) {
      childImageSharp {
        fluid {
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    teamPhoto: file(
      relativePath: { eq: "bloom-students-teachers-family.jpeg" }
    ) {
      childImageSharp {
        fluid {
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allSanityTeamPerson {
      nodes {
        name
        currentlyActive
        designation
        funQuestion
        description
        funAnswer
        whatDoIDoAtBloom
        mainImage {
          asset {
            fixed(width: 200) {
              src
              base64
              srcSetWebp
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  }
`

const Team = ({ data }) => {
  const people = data.nodes
  return (
    <div className="md:mt-16">
      {people.map((person, index) => (
        <Person data={person} key={index} order={index % 2} />
      ))}
    </div>
  )
}

export default function ({ data }) {
  return (
    <Layout>
      <SEO title="Team"/>
      <section className="px-4 w-full lg:border-l lg:border-b border-gray-600 lg:text-center md:pb-20 lg:px-20 md:py-16">
        <div className="section-for-small-devices">
          <Img
            className=""
            fluid={data.teamPhoto.childImageSharp.fluid}
            alt="A team of students and teachers"
            draggable={false}
          />

          <h2 className="section-head mt-4 text-4xl xl:text-5xl">
            We are just a team of{" "}
            <span className="text-red-700">Education Enthusiasts.</span>
          </h2>
        </div>
      </section>
      <section className="p-0 md:mt-24 mt-12 bg-transparent">
        <Team data={data.allSanityTeamPerson} />
      </section>
      <section className="px-4 md:py-16 w-full lg:border-r lg:border-b border-gray-600 md:text-center  md:pb-20 lg:px-32">
        <div className="section-for-small-devices">
          <h1 className="section-head text-red-700 text-6xl">
            Want to Join the team?
          </h1>
          <div className="mt-6">
            <Img
              className="mt-4 lg:mt-6"
              fluid={data.boysGang.childImageSharp.fluid}
              alt="A group young boys students similing"
              draggable={false}
            />
          </div>
          <div className="lg:mt-8 mt-6">
            <p>
              If you deeply believe in education as a tool to change the future,
              we would love to take this conversation forward.
            </p>
            <div className="md:mt-6 mt-4">
              <CTAInLink
                text="CAREERS"
                linkto="/careers"
                className="hover:bg-blue-900 bg-blue-800"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}